<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
    <div class="grid fadein animation-duration-300">
		<div class="col-12">
			<div class="card">
                <div class="flex flex-wrap justify-content-center grid">
                    <i class="pi pi-check-circle mb-5" :style="{fontSize: '5rem', color: 'var(--green-500)'}"></i>
                </div>
                <div class="flex flex-wrap justify-content-center grid">
                    <h2 class="mb-5">Donasi Berhasil</h2>
                </div>
                <div class="flex flex-wrap justify-content-center grid">
                    <div class="text-700 text-sm col-5 flex flex-wrap justify-content-end">No. Kwitansi</div>
                    <div class="col-1">:</div>
                    <div class="text-900 text-sm col-5 flex flex-wrap justify-content-start">{{ noKwitansi(detailDonasi.id) }}</div>
                </div>
                <div class="flex flex-wrap justify-content-center grid">
                    <div class="text-700 text-sm col-5 flex flex-wrap justify-content-end">Dari</div>
                    <div class="col-1">:</div>
                    <div class="text-900 text-sm col-5 flex flex-wrap justify-content-start">{{ detailDonasi.nama_donatur }}</div>
                </div>
                <div class="flex flex-wrap justify-content-center grid">
                    <div class="text-700 text-sm col-5 flex flex-wrap justify-content-end">Alamat</div>
                    <div class="col-1">:</div>
                    <div class="text-900 text-sm col-5 flex flex-wrap justify-content-start">{{ detailDonasi.alamat_donatur }}</div>
                </div>
                <div class="flex flex-wrap justify-content-center grid">
                    <div class="text-700 text-sm col-5 flex flex-wrap justify-content-end">Sejumlah</div>
                    <div class="col-1">:</div>
                    <div class="text-900 text-sm col-5 flex flex-wrap justify-content-start">{{ formatCurency(detailDonasi.nominal) }}</div>
                </div>
                <div class="flex flex-wrap justify-content-center grid">
                    <div class="text-700 text-sm col-5 flex flex-wrap justify-content-end">Keterangan</div>
                    <div class="col-1">:</div>
                    <div class="text-900 text-sm col-5 flex flex-wrap justify-content-start">{{ detailDonasi.keterangan }}</div>
                </div>
                <div class="flex flex-wrap justify-content-center grid mt-3">
                    <Button label="Cetak Kwitansi" class="p-button-raised p-button-success w-5" @click="cetakKwitansi" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Donasi from '../api/Donasi'

    export default {
        name: 'DonasiSukses',
        created() {
            this.donasi = new Donasi();
            this.donasi.get(this.idDonasi)
                .then(async response => {
                    this.detailDonasi = response.data
                })
                .catch(error => {
                   console.log(error.response);
                })
        },
        props: {
            idDonasi: String,
        },
        data() {
            return {
                detailDonasi: [],
                output: null,
                dialogVisible: false,
            }
        },
        methods: {
            formatCurency(value) {
                let formatter = new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                    maximumFractionDigits: 0,
                });
                return formatter.format(value);
            },
            noKwitansi(value) {
                return ('0000000' + value).substr(-8);
            },
            cetakKwitansi() {
				this.$router.push({name: 'Kwitansi', params: { idDonasi: this.idDonasi} })
            },
        },
        mounted() {
        }
    }
</script>